
import Vue from 'vue';
import i18n from '@/core/plugins/i18n';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import Component from 'vue-class-component';
import LanguageSwitcher from '@/general/infrastructure/LanguageSwitcher.vue';
import { LoginFindToken } from '@/courier/application/uses_cases/login/search/findtoken/LoginFindToken';
import { showAlert } from '@/core/plugins/Notifications';
import CustomerRegistration from './customerRegistration/CustomerRegistration.vue';
import { getRoutesApi } from '@/core/config/ConfigurationServer';
import { FindLoginImageConfigByTypeAndCompany } from '@/settings/application/uses_cases/loginImageConfig/search/FindLoginImageConfigByTypeAndCompany';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';

@Component({
  name: 'Login',
  components: {
    LanguageSwitcher,
    CustomerRegistration
  }
})
export default class Login extends Vue {
  @Inject(TYPES.LOGIN_USER)
  readonly loginFindToken!: LoginFindToken;
  @Inject(TYPES.LOGIN_IMAGE_FIND_BY_TYPE_AND_COMPANY)
  readonly findLoginImage!: FindLoginImageConfigByTypeAndCompany;
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFile!: FindFile;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly companyFindById!: CompanyFindById;

  //Data
  widthResize = 0;
  inputType = 'password';
  latitude = 0;
  isLoading = false;
  fullPage = true;
  // urlLogoCompany = '';
  dataCompany: any | null = null;
  form: any = {
    username: '',
    password: ''
  };
  customLoginBackground: any | null = null;

  //Refs
  $refs!: {
    username: HTMLInputElement;
    btnLogin: HTMLButtonElement;
    formLogin: InstanceType<typeof ValidationObserver>;
    registrationForm: HTMLFormElement;
  };
  //Hook
  created() {
    this.dataCompany = JSON.parse(localStorage.getItem('companyData') as string);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }
  async mounted() {
    if (this.dataCompany) {
      await getRoutesApi(['config', 'auth'], this.dataCompany.profile);
      this.getCustomLoginImage();
    }
    this.$nextTick(() => this.$refs.username.focus());
  }
  //Properties Computed
  get marginLogin() {
    return this.widthResize < 450 ? 'container-logo--margin' : '';
  }

  get urlLogoCompany() {
    return this.findFile.execute(this.dataCompany.urlLogo);
  }

  set urlLogoCompany(value: string) {
    this.urlLogoCompany = value;
  }

  get bgLogin() {
    return !this.customLoginBackground && !this.isLoading
      ? `background-image: url(${require('@/core/assets/images/aris-logo-login-page.webp')})`
      : `background-image: url(${this.customLoginBackground})`;
  }
  //Methods
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  handleResize() {
    this.widthResize = window.innerWidth;
  }
  Login() {
    this.isLoading = true;
    const payload: any = {
      username: this.form.username.trim(),
      password: this.form.password.trim()
    };
    this.loginFindToken
      .execute(payload)
      .then((response: any) => {
        if ('error' in response || 'message' in response) {
          showAlert('warning', i18n.t('generalmessage.ERROR_MESSAGE_LOGIN'));
        } else {
          this.$toasted.show(i18n.t('general.welcome') + ` ${response.customerName} !`);
          this.clearForm();
        }
        this.isLoading = false;
        return response;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  clearForm() {
    this.form.username = '';
    this.form.password = '';
    this.$refs.formLogin.reset();
    this.$refs.username.focus();
  }

  registerCustomer() {
    this.$router.replace({ name: 'Registration' });
  }

  async getCustomLoginImage() {
    try {
      const payload = {
        type: 'CUSTOMER_HOME',
        companyId: this.dataCompany.id
      };

      // this.isLoading = true;
      const imageObj = await this.findLoginImage.execute(payload);

      // console.log('imageObj', imageObj);

      if ('error' in imageObj || 'message' in imageObj) {
        this.isLoading = false;
        return;
      }

      this.customLoginBackground = this.findFile.execute(imageObj.file);

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
